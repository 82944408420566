//9:管理者９が最高管理者
module.exports = {
  surveyCode: {
    10010: '人事評価力診断',
    20110: '研修前後アンケート', //マルハニチロさん向け
    30010: 'E3-Survey',
    40010: '360度診断',
    50010: 'モチベーション・トレンド診断診断',
    60010: 'フォロワーシップ診断',
    70010: '女性活躍組織診断',
    90101: '人事評価力診断コース', //9:コース、01:第一カテゴリー、01：連番
    90301: 'E3-Surveyコース', //9:コース、01:第一カテゴリー、01：連番
    90501: 'モチベーション・トレンド診断コース', //9:コース、01:第一カテゴリー、01：連番
    90601: 'フォロワーシップ診断コース', //9:コース、01:第一カテゴリー、01：連番
    99010: 'その他', //9:コース、01:第一カテゴリー、01：連番
  },
};
