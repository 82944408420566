import React, { useState, useEffect, Fragment, useContext } from 'react';
import { UserContext } from '../NewTakeSurveyPage';
import { surveyCode } from '../../../Config/surveyCode';
import { Card, Typography, Radio, Input, Checkbox, Row } from 'antd';

const { TextArea } = Input;
const { Title } = Typography;
const { Text } = Typography;
let justify = 'space-between';
function FixedQuestionPage(props) {
  const { tokenInfo } = props;
  const { section } = props;
  const { questionInfo } = props;
  const { answer, setAnswer } = useContext(UserContext);
  const [checkAns, setCheckAns] = useState([]);
  if (!tokenInfo) return null;
  // console.log({ tokenInfo });
  // console.log({ section });
  // console.log({ questionInfo });
  let surveyTitle = surveyCode[tokenInfo.survey_code];
  if (tokenInfo.survey_code === '30010') {
    surveyTitle = '従業員エンゲージメント調査　『E³-survey』';
  } else if (tokenInfo.survey_code === '40010') {
    surveyTitle = tokenInfo.tName;
  }

  // let surveyTitle = '従業員エンゲージメント調査　『E³-survey』';

  const SurveyTitle = (props) => {
    // console.log({ tokenInfo });
    // console.log({ props });
    switch (props.sc) {
      case '10010':
        justify = 'space-between';
        // return (
        //   <Card
        //     title={surveyTitle}
        //     style={{
        //       margin: '0px',
        //       borderRadius: '10px',
        //       overflow: 'hidden',
        //     }}
        //     headStyle={{
        //       color: 'white',
        //       backgroundColor: 'rgba(83, 131, 236, 1)',
        //       border: 1,
        //     }}
        //   >
        //     <Title level={4}>下記の質問にお答えください。</Title>
        //     <Text>
        //       こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
        //       <br />
        //       全ての質問にお答えください。
        //     </Text>
        //   </Card>
        // );
        switch (props.sect) {
          case '10':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>1問~30問にお答えください。</Title>
                <Text>
                  こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
                  <br />
                  「その通りである」「その通りではない」の２択でお答えください。
                </Text>
              </Card>
            );
          case '20':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>31問~60問にお答えください。</Title>
                <Text>
                  こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
                  <br />
                  「その通りである」「その通りではない」「どちらともいえない」の３択でお答えください。
                </Text>
              </Card>
            );
          case '30':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>61問~70問にお答えください。</Title>
                <Text>
                  こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
                  <br />
                  「その通りである」「その通りではない」の２択でお答えください。
                </Text>
              </Card>
            );
          case '40':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>71問~80問にお答えください。</Title>
                <Text>
                  こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
                  <br />
                  「その通りである」「その通りではない」の２択でお答えください。
                </Text>
              </Card>
            );

          default:
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>下記の設問にお答えください。</Title>
                <Text>
                  こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
                  <br />
                  全ての設問にお答えください。
                </Text>
              </Card>
            );
        }
      case '20110':
        justify = 'space-between';
        return <br />;
      case '40010':
        justify = 'space-between';
        return (
          <Card
            title={surveyTitle}
            style={{
              margin: '0px',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
            headStyle={{
              color: 'white',
              backgroundColor: 'rgba(83, 131, 236, 1)',
              border: 1,
            }}
          >
            <Title level={4}>対象者：{tokenInfo.hero_name}様</Title>
          </Card>
        );
      case '30010':
        justify = 'space-between';
        switch (props.sect) {
          case '10':
          case '20':
          case '30':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  従業員エンゲージメント調査（会社・経営に関する質問）
                </Title>
                <Text>
                  まずは、あなたの会社、または会社における経営層についてお伺いします
                  <br />
                  以下の設問は、正誤があるわけではなく、ご自身の今の考えや思いに最も近い選択肢をお選びください
                  <br />
                  ＊出向されている方は、出向先の会社について回答をお願いします
                </Text>
              </Card>
            );
          case '40':
          case '50':
          case '60':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  従業員エンゲージメント調査（上司に関する質問）
                </Title>
                <Text>
                  ここからは、あなたの上司（あなたの直属の上司・評価者）についてお伺いします
                  <br />
                  以下の設問は、正誤があるわけではなく、ご自身の今の考えや思いに最も近い選択肢をお選びください
                  <br />
                  ＊職場メンバーとは自身が所属している部署（課）や事業部などのメンバーを指します
                  <br />
                  ＊出向されている方は、出向先の会社について回答をお願いします
                </Text>
              </Card>
            );
          case '70':
          case '80':
          case '90':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  従業員エンゲージメント調査（職場・職場メンバーに関する質問）
                </Title>
                <Text>
                  ここからは、あなたの職場・職場メンバーについてお伺いします
                  <br />
                  以下の設問は、正誤があるわけではなく、ご自身の今の考えや思いに最も近い選択肢をお選びください
                  <br />
                  ＊職場メンバーとは自身が所属している事業部や部門（課）などのメンバーを指します
                  <br />
                  ＊出向されている方は、出向先の会社について回答をお願いします
                </Text>
              </Card>
            );
          case '100':
          case '110':
          case '120':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  従業員エンゲージメント調査（職務に関する質問）
                </Title>
                <Text>
                  ここからは、あなたの職務についてお伺いします
                  <br />
                  以下の設問は、正誤があるわけではなく、ご自身の今の考えや思いに最も近い選択肢をお選びください
                  <br />
                  ＊職務とはあなたが現在担当している業務や役割を指します
                  <br />
                  ＊出向されている方は、出向先の会社について回答をお願いします
                </Text>
              </Card>
            );
          default:
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>下記の設問にお答えください。</Title>
                <Text>
                  こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
                  <br />
                  全ての設問にお答えください。
                </Text>
              </Card>
            );
        }
      case '70010':
        justify = 'space-between';
        switch (props.sect) {
          case '10':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  <font color="red">
                    <b>あなた自身</b>
                  </font>
                  についてお聞きします
                </Title>
              </Card>
            );
          case '20':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなたの会社</b>
                  </font>
                  についてお聞きします
                </Title>
              </Card>
            );
          case '30':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなたの上司</b>
                  </font>
                  についてお聞きします
                </Title>
              </Card>
            );
          case '40':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなた自身</b>
                  </font>
                  についてお聞きします
                </Title>
              </Card>
            );
          case '50':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  <font color="red">
                    <b>あなた自身</b>
                  </font>
                  についてお聞きします。直感的に率直にお答えください
                </Title>
              </Card>
            );
          case '60':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなたの職場</b>
                  </font>
                  についてお聞きします
                </Title>
              </Card>
            );
          case '70':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなた自身</b>
                  </font>
                  についてお聞きします
                  <br />
                  あなた自身はどう感じますか。それぞれもっとも考えに近いものを選んでください
                </Title>
              </Card>
            );
          case '80':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなたの職場のメンバー</b>
                  </font>
                  についてお聞きします
                  <br />
                  あなたの職場のメンバーはどう感じると思いますか。それぞれもっとも考えに近いものを選んでください
                </Title>
              </Card>
            );
          case '90':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなたの上司</b>
                  </font>
                  についてお聞きします
                  <br />
                  あなたの上司はどう感じると思いますか。それぞれもっとも考えに近いものを選んでください
                </Title>
              </Card>
            );
          case '100':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは
                  <font color="red">
                    <b>あなた自身</b>
                  </font>
                  についてお聞きします
                  <br />
                  あなたの普段の行動やふるまいに、どの程度当てはまりますか
                </Title>
              </Card>
            );
          case '110':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  <font color="red">
                    <b>あなた自身</b>
                  </font>
                  についてお聞きします
                  <br />
                  現在働いている会社で、以下のような点で見習いたい・参考にしたい人はいますか。あるいはいましたか
                </Title>
              </Card>
            );
          case '120':
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Title level={4}>
                  ここからは<b>あなたの属性 及びご意見</b>
                  についてお聞きします
                </Title>
              </Card>
            );
          default:
            return (
              <Card
                title={surveyTitle}
                style={{
                  margin: '0px',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  fontSize: '16px',
                }}
                headStyle={{
                  color: 'white',
                  backgroundColor: 'rgba(83, 131, 236, 1)',
                  border: 1,
                }}
              >
                <Text type="danger">直感的に感じたことをご回答ください</Text>
              </Card>
            );
        }
      default:
        return (
          <Fragment>
            <Title level={4}>下記の設問にお答えください。</Title>
            <Text>
              こちらの設問には正誤はありません。ご自身が評価する際の判断軸に近い方をお選びください。
              <br />
              全ての設問にお答えください。
            </Text>
          </Fragment>
        );
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    // ans[e.target.name] = e.target.value;
    setAnswer({ ...answer, [e.target.name]: e.target.value });
    // console.log(e);
    // console.log(answer);
  };

  const onChangeCheckBox = (checkedValues) => {
    // console.log('checked = ', checkedValues);
    // setCheckAns(checkedValues);
  };
  const onChangeCheck = async (e) => {
    // setAnswer({ ...answer, [e.target.name]: e.target.value });
    // console.log({ e });
    let ansStringArr = [];
    if (answer[e.target.name]) {
      ansStringArr = answer[e.target.name].split(',');
    }
    // console.log({ ansStringArr });
    let newAnsStringArr = [];
    if (e.target.checked) {
      ansStringArr.push(e.target.value.toString());
      newAnsStringArr = ansStringArr;
    } else {
      for (let i = 0; i < ansStringArr.length; i++) {
        if (ansStringArr[i] != e.target.value.toString()) {
          newAnsStringArr.push(ansStringArr[i]);
        }
      }
    }
    const joinedString = newAnsStringArr.join(',');
    // console.log({ joinedString });
    // console.log({ newAnsStringArr });
    // await setAnswer({ ...answer, [e.target.name]: joinedString });
    // setCheckAns(newAnsStringArr);
    const numberCheckValue = await newAnsStringArr.map((x) => Number(x));
    // setCheckAns(numberCheckValue);
    // console.log({ numberCheckValue });
    await setAnswer({
      ...answer,
      [e.target.name]: joinedString,
      [e.target.name + 2000]: numberCheckValue,
    });
    // console.log({ answer });
  };

  return (
    <div style={{ width: '90%', margin: '0rem auto' }}>
      <SurveyTitle sc={tokenInfo.survey_code} sect={section} />
      {questionInfo.map((element, index) => {
        // setShowQ(true);
        let showQ = false;
        // element.reqOrigin = element.required;
        // console.log(element);
        for (let i = 1; i < element.if_question.length; i++) {
          //チェックしたい回答がある分回す（一般設問は実行しない）
          // console.log(`choices.length:${element.choices.length}`);
          // console.log(`if_question[i]:${element.if_question[i]}`);
          if (answer[element.if_question[0]] === element.if_question[i]) {
            //チェックしたい回答の値があるかを検査
            showQ = true;
          }
        }
        // console.log({ showQ });
        if (element.if_question.length > 0 && !showQ) {
          //チェックしたい答案があって、回答が一致しない場合
          delete answer[element.no]; //非表示の設問の回答を削除
          delete answer[element.no + 1000]; //type 30の自由技術情報
          delete answer[element.no + 2000]; //type 40, 50の多重選択肢 Array [1,2,3]
          delete answer[element.no + 3000]; //type 50のの自由技術情報

          element.required = false; //非表示の設問を任意に
          return <Fragment key={index}></Fragment>; //設問表示しない
        } else {
          //チェックしたい設問がない（一般設問）　or チエック結果が一致する場合
          if (element.if_question.length > 0) {
            //チェックしたい設問がある場合
            // element.required = true; //表示に切り替えた設問を必須に
            element.required = element.reqOrigin; //表示に切り替えた設問を必須に
          }
          return (
            <Fragment key={index}>
              <br />
              <Card
                style={
                  answer[element.no]
                    ? {
                        margin: '0px',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        backgroundColor: 'rgba(216, 216, 203, 0.3)',
                        fontSize: '18px',
                      }
                    : {
                        margin: '0px',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        backgroundColor: 'white',
                        fontSize: '18px',
                      }
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${element.no}. ${element.question.replace(
                      /\r?\n/g,
                      '<br />'
                    )}`,
                  }}
                ></div>
                <br />
                <br />
                {element.type === '20' ? (
                  <TextArea
                    rows={4}
                    name={element.no.toString()}
                    onChange={onChange}
                    value={answer[element.no]}
                  />
                ) : element.type === '30' ? (
                  <Fragment>
                    <Radio.Group
                      name={element.no.toString()}
                      onChange={onChange}
                      value={answer[element.no]}
                      style={{ fontSize: '14px' }}
                    >
                      {element.choices.map((choice, index2) => {
                        return (
                          <Fragment key={index2 + 1000}>
                            {tokenInfo.survey_code === '30010' ? (
                              <Radio value={8 - (index2 + 1)}>{choice}</Radio>
                            ) : (
                              <Radio value={index2 + 1}>{choice}</Radio>
                            )}
                            <br />
                            <br />
                          </Fragment>
                        );
                      })}
                    </Radio.Group>
                    {answer[element.no] === element.free_after ? (
                      <TextArea
                        rows={4}
                        name={(element.no + 1000).toString()}
                        onChange={onChange}
                        value={answer[element.no + 1000]}
                      />
                    ) : (
                      <Fragment>{delete answer[element.no + 1000]}</Fragment>
                    )}
                  </Fragment>
                ) : element.type === '40' ? (
                  <Fragment>
                    <Checkbox.Group
                      style={{ width: '100%', fontSize: '14px' }}
                      onChange={onChangeCheckBox}
                      name={element.no}
                      value={answer[element.no + 2000]}
                    >
                      {element.choices.map((choice, index2) => {
                        return (
                          <Fragment key={index2 + 1000}>
                            <Checkbox
                              value={index2 + 1}
                              onChange={onChangeCheck}
                              name={(index2 + 1).toString()}
                            >
                              {choice}
                            </Checkbox>
                            <br />
                          </Fragment>
                        );
                      })}
                    </Checkbox.Group>
                  </Fragment>
                ) : element.type === '50' ? (
                  <Fragment>
                    <Checkbox.Group
                      style={{ width: '100%', fontSize: '14px' }}
                      onChange={onChangeCheckBox}
                      name={element.no}
                      value={answer[element.no + 2000]}
                    >
                      {element.choices.map((choice, index2) => {
                        return (
                          <Fragment key={index2 + 1000}>
                            <Checkbox
                              value={index2 + 1}
                              onChange={onChangeCheck}
                              name={(index2 + 1).toString()}
                            >
                              {choice}
                            </Checkbox>
                            <br />
                          </Fragment>
                        );
                      })}
                    </Checkbox.Group>
                    {answer[element.no + 2000] &&
                    answer[element.no + 2000].includes(element.free_after) ? (
                      <TextArea
                        rows={4}
                        name={(element.no + 3000).toString()}
                        onChange={onChange}
                        value={answer[element.no + 3000]}
                      />
                    ) : (
                      <Fragment>{delete answer[element.no + 3000]}</Fragment>
                    )}
                  </Fragment>
                ) : (
                  //type = 10
                  <Radio.Group
                    name={element.no.toString()}
                    onChange={onChange}
                    value={answer[element.no]}
                    style={{ fontSize: '14px' }}
                  >
                    {element.choices.map((choice, index2) => {
                      if (tokenInfo.survey_code === '30010') {
                        return (
                          <Fragment key={index2 + 1000}>
                            <Radio value={8 - (index2 + 1)}>{choice}</Radio>
                            <br />
                            <br />
                          </Fragment>
                        );
                        // } else if (tokenInfo.survey_code === '20110' && element.type==='10') {
                        //   return (
                        //     <Fragment key={index2 + 1000}>
                        //       <Radio value={element.choices.length+1 - (index2 + 1)}>{choice}</Radio>
                        //       <br />
                        //       <br />
                        //     </Fragment>
                        //   );
                      } else {
                        return (
                          <Fragment key={index2 + 1000}>
                            <Radio value={index2 + 1}>{choice}</Radio>
                            <br />
                            <br />
                          </Fragment>
                        );
                      }
                    })}
                  </Radio.Group>
                )}
              </Card>
            </Fragment>
          );
        }
      })}
    </div>
  );
}

export default FixedQuestionPage;
