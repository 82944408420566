import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { Table, Spin, Button, message, Space, Modal } from 'antd';
import config from '../../Config/key';
import { surveyCode } from '../../Config/surveyCode';
import { surveyStatus } from '../../Config/surveyStatus';
import { BsBoxArrowUpRight } from 'react-icons/bs';
// import GuidePage from '../TakeSurveyPage/Sections/GuidePage';
const { Column } = Table;
const { confirm } = Modal;
let tUsers = [];
let selSurveySeasonId = '';

function MySurveyPage(props) {
  // console.log({ props });
  const { user } = props;
  const [mySurvey, setMySurvey] = useState([]);
  const [selSeasonInfo, setSelSeasonInfo] = useState([]);
  const [groupInfo, setGroupInfo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [tokenInfo, setTokenInfo] = useState();
  // const [questionInfo, setQuestionInfo] = useState([]);
  //const [myUser, setMyUser] = useState([]);
  // let user = useSelector((state) => state.user);
  // const history = useHistory();
  //if (!user.userData) return null;

  //console.log({ myUser });

  //yyyy/mm/dd hh:mm
  const ymdhms = (tdate) => {
    let date = new Date(tdate);
    //console.log({ date });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
      // date.getMilliseconds()
    );
  };
  //yyyy/mm/dd
  const ymd = (tdate) => {
    let date = new Date(tdate);
    // console.log({ tdate });
    return (
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2)
      // date.getMilliseconds()
    );
  };
  const isPassed = (targetDate) => {
    const now = new Date();
    // console.log(ymdhms(surveySeasonInfo.output_360_date));
    // console.log(ymdhms(targetDate));
    // console.log(ymdhms(now));
    return ymdhms(targetDate) < ymdhms(now);
  };

  const readDB = async () => {
    try {
      if (!user.userData) return null;
      // console.log(user.userData.isAuth);
      if (user.userData.isAuth) {
        message.loading({
          content: '読み込み中...',
          key: 'updateable',
          duration: 20,
        });
        const [surveyInfo, seasonInfo, tokenInfo, gUsers, group] =
          await Promise.all([
            Axios.get('/api/survey/'),
            Axios.get('/api/surveySeason/'), //DBからシーズン情報取得
            Axios.get(`api/surveyToken/u/${user.userData._id}`),
            //グループの中に多人数が存在する場合時間がかかる原因になるため、360の本人の場合のみ実行することに変更→復元した
            Axios.get(`${config.USER_SERVER}/group/${user.userData.groupCode}`),
            Axios.get(`/api/group/${user.userData.groupCode}`),
            // Axios.get(`api/relation360/u/${user.userData._id}`), //結果があるのは本人
          ]);
        //Promise.allがどきどきNginxで動作しない疑いがあり、下記のように修正
        // const surveyInfo = await Axios.get('/api/survey/');
        // console.log('1');
        // const seasonInfo = await Axios.get('/api/surveySeason/'); //DBからシーズン情報取得
        // console.log('2');
        // const tokenInfo = await Axios.get(
        //   `api/surveyToken/u/${user.userData._id}`
        // );
        // console.log('3');
        // const gUsers = await Axios.get(
        //   `${config.USER_SERVER}/group/${user.userData.groupCode}`
        // );
        // console.log('4');
        // const group = await Axios.get(`/api/group/${user.userData.groupCode}`);
        // console.log('5');
        //ここまで
        tUsers = gUsers.data.users;

        // console.log(relation360.data.relation360);
        let surveyToken = tokenInfo.data.token.concat();
        let sortedToken = tokenInfo.data.token.concat().sort(function (a, b) {
          return a.updatedAt > b.updatedAt ? -1 : 1; //オブジェクトの昇順ソート
        });
        // console.log({ surveyToken });
        // console.log({ sortedToken });
        let surveySeason = seasonInfo.data.surveySeason;
        let survey = surveyInfo.data.survey;
        setGroupInfo(group.data.group);
        const gInfo = group.data.group;
        // let relationInfo = relation360.data.relation360;
        // console.log({ gUsers });

        // console.log(user.userData.groupCode);
        if (!surveyInfo.data.survey)
          throw new Error('サーベイ取得に失敗しました。');
        if (!surveyToken) throw new Error('トークン取得に失敗しました。');
        const onDateTime = new Date();
        // console.log({ surveyToken });
        await surveyToken.map(async (token) => {
          token.surveyName = surveyCode[token.survey_code];
          if (
            token.survey_status === 0 &&
            token.token_expire < onDateTime.toISOString()
          ) {
            token.survey_status = 7;
          }
          token.surveyStatusName = surveyStatus[token.survey_status];
          token.token_valid = ymdhms(token.token_valid);
          token.token_expire = ymdhms(token.token_expire);
          // if (token.question_set_id) {
          //   //設問セットがある場合は設問セット名も一緒に表記
          //   let survey = surveyInfo.data.survey.find(
          //     (item) => item._id === token.survey_id
          //   );
          //   // console.log({ surveyInfo });
          //   token.surveyName =
          //     surveyCode[token.survey_code] +
          //     '(' +
          //     survey.question_set.find(
          //       (item) => item._id === token.question_set_id
          //     ).set_name +
          //     ')';
          // }
          let surveyName = '';
          let tName = '';
          let relationInfo = [];
          //シーズンIDが存在するならシーズン名を表示
          if (token.survey_season_id) {
            selSurveySeasonId = token.survey_season_id;
            //全体シーズン情報からこのTokenのSurveySeasonIdと同じシーズン情報を１個格納
            let surveySeasonInfo = surveySeason.find(
              (item) => item._id === token.survey_season_id
            );
            // console.log({ surveySeasonInfo });
            // console.log(user.userData._id);
            token.active = surveySeasonInfo.active;
            surveyName = surveySeasonInfo.season_name;
            tName = surveySeasonInfo.t_name;
            setSelSeasonInfo(surveySeasonInfo);
            //このTokenのSurveySeasonに含まれている360リレーション情報の中でログインユーザが本人になっているリレーション情報を格納
            // console.log({ token });

            if (token.survey_code === '40010') {
              if (token.relation === 1) {
                relationInfo = surveySeasonInfo.relation_360.find(
                  (item) => item.user_id === user.userData._id
                );

                //本人の場合
                //ログインユーザが本人の場合のみ情報がある
                token.relationInfo = relationInfo;
                token.output_360_type = surveySeasonInfo.output_360_type; //０：非公開、１：受験後公開、２：日付で公開
                token.output_360_date = surveySeasonInfo.output_360_date; //公開日：00:00時
                token.test = 0;
                // let data = {};
                const data = makeTeam(relationInfo);

                // makeTeam(relationInfo)
                //   .then((item) => {
                //     console.log({ item });
                //     data = item;
                //     data.cnt += 1; //本人分の追加
                //     data.done = data.done + (token.survey_status === 5 ? 1 : 0);
                //     token.allStatus = data.cnt === data.done ? 1 : 0;
                //   })
                //   .catch((e) => {
                //     console.error(e);
                //   });
                // console.log({ data });
                data.cnt += 1; //本人分の追加
                data.done = data.done + (token.survey_status === 5 ? 1 : 0);
                token.allStatus = data.cnt === data.done ? 1 : 0;
                // token.allStatus = 0;
                // console.log(
                //   `data.cnt:${data.cnt}, data.done:${data.done}, token.allStatus:${token.allStatus}`
                // );
                // console.log(isPassed(surveySeasonInfo.output_360_date));
                let lastSeasonTokenInfo = sortedToken.find(
                  (tokenInfo) =>
                    tokenInfo.survey_status === 5 &&
                    tokenInfo.relation === 1 &&
                    tokenInfo.survey_code === '40010' &&
                    tokenInfo.survey_season_id != token.survey_season_id &&
                    ymd(tokenInfo.updatedAt) < ymd(token.updatedAt) &&
                    tokenInfo.question_set_id === token.question_set_id
                );
                let lastSeason = false;
                if (lastSeasonTokenInfo) {
                  const lastSurveySeason = gInfo.survey_season.find(
                    (item) => item._id === lastSeasonTokenInfo.survey_season_id
                  );
                  // console.log(lastSurveySeason);
                  lastSeason = isLastSeasonComplete(
                    user.userData,
                    lastSurveySeason
                  );
                }
                token.lastSeason = lastSeason;
                if (lastSeason) {
                  token.lastSeasonTokenInfo = lastSeasonTokenInfo;
                  token.lastSurveySeason = gInfo.survey_season.find(
                    (item) => item._id === lastSeasonTokenInfo.survey_season_id
                  );
                }
              } //else {
              //本人ではない場合
              // for (let i = 0; i < surveySeasonInfo.relation_360.length; i++) {
              //   const element = surveySeasonInfo.relation_360[i];
              //   for (let j = 0; j < element.boss.length; j++) {
              //     const boss_id = element.boss[j];
              //     if (user.userData._id === boss_id) {
              //       token.hero_id = element.user_id;
              //     }
              //   }
              //   for (let k = 0; k < element.partner.length; k++) {
              //     const partner_id = element.partner[k];
              //     if (user.userData._id === partner_id) {
              //       token.hero_id = element.user_id;
              //     }
              //   }
              // }
              // let selUser = tUsers.find((item) => item._id === token.hero_id);
              // token.hero_name = selUser.lastname + ' ' + selUser.name;
              // console.log({ token });
              // }
            } else if (
              token.survey_code === '90101' ||
              token.survey_code === '90501'
            ) {
              token.coursePartView = surveySeasonInfo.course_part_view;
            } else if (token.survey_code === '90301') {
              token.coursePartView = surveySeasonInfo.course_part_view;
              token.e3_option = surveySeasonInfo.e3_option;
            } else if (token.survey_code === '30010') {
              token.e3_option = surveySeasonInfo.e3_option;
            }
            // console.log({ surveySeasonInfo });
          } else {
            surveyName = surveyCode[token.survey_code];
          }
          //Question Set Idが存在するならQuestionSet名を表示
          if (token.question_set_id) {
            let surveyInfo = survey.find(
              (item) => item._id === token.survey_id
            );
            surveyName =
              surveyName +
              ' : ' +
              surveyInfo.question_set.find(
                (item) => item._id === token.question_set_id
              ).set_name;
          }
          if (token.survey_code === '40010') {
            if (token.hero_id && token.relation !== 1) {
              surveyName = surveyName + `(${token.hero_name}様向け)`;
              // console.log(token.hero_name);
            }
          }
          token.surveyName = surveyName;
          token.tName = tName;
          // if (relationInfo) {
          //   console.log({ relationInfo });
          //   //   //ログインユーザが本人の場合のみ情報がある
          //   //   token.relationInfo = relationInfo;
          //   let data = makeTeam(relationInfo);
          //   data.cnt += 1; //本人分の追加
          //   data.done = data.done + (token.survey_status === 5 ? 1 : 0);
          //   token.totalStatus = data.cnt === data.done ? true : false;
          //   //   // console.log({ data });
          // }
          return token;
        });
        // console.log(
        //   surveyToken.filter((item) => item.active && item.survey_status != 7)
        // );
        setMySurvey(
          surveyToken.filter((item) => item.active && item.survey_status != 7)
        );
        // setMySurvey(surveyToken);
        message.success({
          content: '読み込み完了！',
          key: 'updateable',
          duration: 2,
        });
        // console.log({ surveyToken });
      } else {
        throw new Error('ログイン前です。');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    readDB();
    // console.log({ mySurvey });
  }, [user]);

  const isLastSeasonComplete = (user, lastSurveySeason) => {
    let doneCnt = 0;
    let totalCnt = 0;
    // let heroCnt = 1;
    let bossCnt = 0;
    let partnerCnt = 0;
    const team = lastSurveySeason.relation_360.find(
      (item) => item.user_id === user._id
    );

    let seasonTokenInfo = {};
    // console.log({ team });
    for (let i = 0; i < team.boss.length; i++) {
      const bossId = team.boss[i];
      const bossInfo = tUsers.find((item) => item._id === bossId);
      if (bossInfo.survey_token.length === 0) {
        bossInfo.status = false;
        seasonTokenInfo = {};
      } else {
        seasonTokenInfo = bossInfo.survey_token.find(
          (tokenInfo) =>
            tokenInfo.survey_season_id === lastSurveySeason._id &&
            tokenInfo.hero_id === user._id
        );
        // console.log({ seasonTokenInfo });
        if (seasonTokenInfo.survey_status === 5) {
          doneCnt++;
          totalCnt++;
          bossCnt++;
        } else {
          totalCnt++;
        }
      }
    }
    for (let i = 0; i < team.partner.length; i++) {
      const partnerId = team.partner[i];
      const partnerInfo = tUsers.find((item) => item._id === partnerId);
      if (partnerInfo.survey_token.length === 0) {
        partnerInfo.status = false;
        seasonTokenInfo = {};
      } else {
        seasonTokenInfo = partnerInfo.survey_token.find(
          (tokenInfo) =>
            tokenInfo.survey_season_id === lastSurveySeason._id &&
            tokenInfo.hero_id === user._id
        );
        // console.log({ seasonTokenInfo });
        if (seasonTokenInfo.survey_status === 5) {
          doneCnt++;
          totalCnt++;
          partnerCnt++;
        } else {
          totalCnt++;
        }
      }
    }
    // console.log({ doneCnt });
    // console.log({ totalCnt });
    return totalCnt === doneCnt && totalCnt > 0 ? true : false;
  };

  const makeTeam = (team) => {
    // const team = selSurveySeason.relation_360.find(
    //   (item) => item.user_id === user._id
    // );
    // if (tUsers.length === 0) {
    //   const gUsers = await Axios.get(
    //     `${config.USER_SERVER}/group/${user.userData.groupCode}`
    //   );
    //   tUsers = gUsers.data.users;
    // }
    let data = { cnt: 0, done: 0 };
    // console.log({ team });
    team.boss.map((bossId) => {
      const bossInfo = tUsers.find((item) => item._id === bossId);
      // console.log({ bossInfo });
      if (bossInfo.survey_token.length === 0) {
        bossInfo.status = false;
      } else {
        let seasonTokenInfo = bossInfo.survey_token.find(
          (tokenInfo) => tokenInfo.survey_season_id === team.survey_season_id
        );
        data.cnt += 1;
        // console.log({ seasonTokenInfo });
        if (seasonTokenInfo.survey_status === 5) {
          data.done += 1;
        }
      }
      return;
    });

    team.partner.map((partnerId) => {
      const partnerInfo = tUsers.find((item) => item._id === partnerId);
      // console.log({ partnerInfo });
      if (partnerInfo.survey_token.length === 0) {
        partnerInfo.status = false;
      } else {
        let seasonTokenInfo = partnerInfo.survey_token.find(
          (tokenInfo) => tokenInfo.survey_season_id === team.survey_season_id
        );
        data.cnt += 1;
        // console.log({ seasonTokenInfo });
        if (seasonTokenInfo.survey_status === 5) {
          data.done += 1;
        }
      }
      return;
    });

    return data;
  };

  const joinSurveyHandler = async (tokenInfo, e) => {
    e.preventDefault();
    let sendData = {};
    try {
      // const res = await Axios.get(`api/surveyToken/t/${tokenId}`);
      // let tokenInfo = res.data.tokenInfo[0];
      // console.log(tokenInfo._id);
      // window.location.href = `/takesurvey/${tokenInfo}`;
      // console.log(tokenInfo);

      //임시적 조치, 기존 서베이가 수정 완료되면 같은곧으로포워드
      // if (tokenInfo.survey_code === '10010') {
      // -----------------------------------------------------------------------
      //人事評価力診断
      //10010はこちら
      // props.history.push(`/takesurvey/${tokenInfo._id}`);
      // } else {
      let steps = [];
      const [res, res2] = await Promise.all([
        await Axios.get(`/api/surveyToken/t/${tokenInfo._id}`),
        await Axios.get(`/api/question/${tokenInfo.survey_code}`),
      ]);
      // console.log(tokenInfo.survey_code);
      // console.log({ res2 });
      // const res = await Axios.get(`/api/surveyToken/t/${tokenInfo._id}`);
      // const res2 = await Axios.get(`/api/question/${tokenInfo.survey_code}`);

      // const res = await Axios.get(`/api/surveyToken/t/${tokenInfo._id}`);
      // const res2 = await Axios.get(
      //   `/api/question/${tokenInfo.survey_code}`
      // );
      if (res.data.success && res2.data.success) {
        const { tokenInfo } = res.data;
        const questionInfo = res2.data.question;
        // setTokenInfo(tokenInfo);
        //question_set_id：あり→可変問題構成
        //question_set_id：なし→固定問題構成
        if (tokenInfo.question_set_id) {
          // -----------------------------------------------------------------------
          //可変問題構成方式
          //20110はこちら

          const res3 = await Axios.get(
            `/api/questionSet/${tokenInfo.question_set_id}`
          );
          const { questionSet } = res3.data;
          // console.log({ questionSet });
          let qi = [];
          questionSet.question_id.forEach((qId, index) => {
            let q = questionInfo.find((item) => item._id === qId);
            q.no = index + 1;
            if (tokenInfo.survey_code === '20110') {
              q.section = '10';
              q.reqOrigin = q.required;
              // q.section = ((parseInt(index / 10) + 1) * 10).toString();
            }
            qi.push(q);
          });
          // console.log({ qi });
          sendData.questionInfo = qi;
          sendData.questionSet = questionSet;
        } else {
          // -----------------------------------------------------------------------
          //固定問題構成方式
          //30010はこちら

          // console.log({ res2 });

          // setQuestionInfo(questionInfo);
          // sendData.questionInfo = questionInfo;
          sendData.questionInfo = questionInfo;
          // console.log({ questionInfo });
        }
        //Section情報のみ数字で取り出してSortをかける
        const secNum = [
          ...new Map(
            await sendData.questionInfo.map((info) => [
              info.section,
              Number(info.section),
            ])
          ).values(),
        ].sort((first, second) => first - second);
        // console.log({ secNum });

        // let aaa = questionInfo.filter(function (question) {
        //   return question.section === '10';
        // });
        // console.log({ aaa });
        // secNum.forEach((secNum) => {
        for (let i = 0; i < secNum.length; i++) {
          const element = secNum[i];
          steps.push({
            section: element.toString(),
            questionInfo: sendData.questionInfo.filter(function (question) {
              return question.section === element.toString();
            }),
          });
        }
      } else {
        throw new Error('サーベイトークン情報取得に失敗しました。');
      }
      // props.history.push(`/newtakesurvey/${tokenInfo._id}`);
      sendData.tokenInfo = tokenInfo;
      sendData.stepArr = steps;
      // console.log({ sendData });
      // console.log({ steps });
      if (tokenInfo.survey_code === '90101') {
        props.history.push({
          pathname: '/course90101',
          state: sendData,
        });
      } else if (tokenInfo.survey_code === '90301') {
        props.history.push({
          pathname: '/course90301',
          state: sendData,
        });
      } else if (tokenInfo.survey_code === '90501') {
        props.history.push({
          pathname: '/course90501',
          state: sendData,
        });
      } else {
        props.history.push({
          pathname: '/newtakesurvey',
          state: sendData,
        });
      }
    } catch (error) {
      alert('サーベイ参加に失敗しました。');
      console.log(error);
    }
  };

  // const surveyDeleteHandler = async (tokenId, e) => {
  //   e.preventDefault();
  //   try {
  //   } catch (error) {
  //     alert('サーベイ削除に失敗しました。');
  //     console.log(error);
  //   }
  // };

  const viewSurveyResultHandler = async (tokenInfo, e) => {
    e.preventDefault();
    try {
      // console.log({ tokenInfo });

      let url = '';
      if (user.userData.groupCode) {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${user.userData.groupCode}/${tokenInfo.user}/rcep_${tokenInfo._id}.pdf`;
      } else {
        url = `${config.S3_URL}/${config.S3_ENV}/user/${tokenInfo.user}/rcep_${tokenInfo._id}.pdf`;
      }
      window.open(url, '_blank');
    } catch (error) {
      alert('サーベイ結果確認ができません。');
      console.log(error);
    }
  };

  // const view360ResultHandler = async (tokenI) => {
  //   try {
  //     // console.log({ tokenI });
  //     const res = await Axios.post('/api/dbModel100/makeReport40010', tokenI);
  //     return res;
  //   } catch (error) {
  //     alert('サーベイ結果確認ができません。');
  //     console.log(error);
  //   }
  // };

  const showConfirm = async (tokenI, type) => {
    confirm({
      title: 'サーベイ結果を生成しますか？',
      content:
        'サーベイ結果を生成し別TabでPDF表示します。このままお待ちください。',
      async onOk() {
        setIsModalVisible(true);
        // return new Promise((resolve, reject) => {
        //   setTimeout(Math.random() > 0 ? resolve : reject, 3000);
        //   ansSendHandler();
        // }).catch(() => console.log('サーベイ結果提出でエラーが発生しました。'));
        // console.log({ tokenI });
        // console.log({ selSeasonInfo });
        tokenI.type = type; //1:個人用、2:共有用, 30010:E3-Survey
        tokenI.group_name = groupInfo.group_name;
        tokenI.training_start_date = selSeasonInfo.training_start_date;
        tokenI.training_end_date = selSeasonInfo.training_end_date;
        tokenI.survey_start_date = selSeasonInfo.survey_start_date;
        tokenI.survey_end_date = selSeasonInfo.survey_end_date;
        tokenI.user_name = `${user.userData.lastname} ${user.userData.name}`;
        let dep = '';
        if (user.userData.department) dep = user.userData.department;
        if (user.userData.department2)
          dep = dep + ' ' + user.userData.department2;
        if (user.userData.department3)
          dep = dep + ' ' + user.userData.department3;
        if (user.userData.department4)
          dep = dep + ' ' + user.userData.department4;
        if (user.userData.department5)
          dep = dep + ' ' + user.userData.department5;
        tokenI.department = dep;
        let result = {};
        if (type === 30010) {
          result = await Axios.post(
            '/api/dbModel100/makeReport30010Personal',
            tokenI
          );
        } else {
          tokenI.type_360 = selSeasonInfo.question_set[0].type_360;
          // console.log(tokenI);
          result = await Axios.post('/api/dbModel100/makeReport40010', tokenI);
        }
        // console.log({ result });
        if (result.data.success) {
          // message.success('結果生成が完了しました。マイ・サーベイに戻ります。');
          // props.history.push('/mysurvey');
          const url = `${config.S3_URL}/${result.data.s3Path}`;
          // console.log({ url });
          setTimeout(() => {
            message.success({
              content: 'ファイル生成完了！',
              key: 'makecsv',
              duration: 2,
            });
            setIsModalVisible(false);
            window.open(url, '_blank');
          }, 3000);
        } else {
          setIsModalVisible(false);
          console.log('結果生成で問題が発生しました。');
          message.error('結果生成で問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  // return <div>User Management Page2</div>;
  return (
    <Fragment>
      <div style={{ width: '90%', margin: '0rem auto' }}>
        <Table dataSource={mySurvey} size="middle" rowKey="_id">
          <Column
            title="サーベイ名"
            dataIndex="surveyName"
            key="surveyName"
            aligh="right"
          />
          <Column
            title="サーベイ開始日"
            dataIndex="token_valid"
            key="token_valid"
            aligh="center"
          />
          <Column
            title="有効期限"
            dataIndex="token_expire"
            key="token_expire"
            aligh="center"
          />
          <Column
            title="状態"
            dataIndex="surveyStatusName"
            key="surveyStatusName"
            aligh="center"
          />
          {/* <Column
            title="allStatus"
            dataIndex="allStatus"
            key="allStatus"
            aligh="center"
          /> */}

          <Column
            title="サーベイ参加"
            key="action"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}
                {record.survey_code === '90101' ||
                record.survey_code === '90301' ||
                record.survey_code === '90501' ? (
                  <Button
                    type={'primary'}
                    size="small"
                    onClick={(e) => joinSurveyHandler(record, e)}
                    disabled={false}
                  >
                    コース参加
                  </Button>
                ) : (
                  <Button
                    type={'primary'}
                    size="small"
                    onClick={(e) => joinSurveyHandler(record, e)}
                    disabled={record.survey_status === 0 ? false : true}
                  >
                    サーベイ参加
                  </Button>
                )}
              </span>
            )}
          />
          <Column
            title="結果確認"
            key="action"
            render={(text, record) => (
              <span>
                {/* {<a>権限付与 {record._id}</a>} */}

                {record.survey_code === '10010' ||
                record.survey_code === '90101' ||
                record.survey_code === '60010' ||
                record.survey_code === '90601' ||
                record.survey_code === '50010' ||
                record.survey_code === '90501' ? (
                  <Button
                    type={'primary'}
                    size="small"
                    onClick={(e) => viewSurveyResultHandler(record, e)}
                    disabled={record.survey_status === 5 ? false : true}
                  >
                    サーベイ結果確認 &nbsp;{' '}
                    <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                  </Button>
                ) : record.survey_code === '40010' &&
                  record.relation === 1 &&
                  record.output_360_type !== 0 ? ( //360診断＋本人の場合+公開設定が非公開じゃない
                  <Space>
                    <Button
                      type={'primary'}
                      size="small"
                      onClick={(e) => showConfirm(record, 1)}
                      disabled={
                        !(
                          (record.output_360_type === 1 &&
                            record.allStatus === 1) ||
                          (record.output_360_type === 2 &&
                            record.allStatus === 1 &&
                            isPassed(record.output_360_date))
                        )
                      }
                    >
                      個人用 &nbsp;{' '}
                      <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                    </Button>
                    <Button
                      type={'primary'}
                      size="small"
                      onClick={(e) => showConfirm(record, 2)}
                      disabled={
                        !(
                          (record.output_360_type === 1 &&
                            record.allStatus === 1) ||
                          (record.output_360_type === 2 &&
                            record.allStatus === 1 &&
                            isPassed(record.output_360_date))
                        )
                      }
                    >
                      共有用 &nbsp;{' '}
                      <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                    </Button>
                    <Button
                      type={'primary'}
                      size="small"
                      onClick={(e) => showConfirm(record, 3)}
                      disabled={
                        !(
                          (record.output_360_type === 1 &&
                            record.allStatus === 1 &&
                            record.lastSeason) ||
                          (record.output_360_type === 2 &&
                            record.allStatus === 1 &&
                            isPassed(record.output_360_date) &&
                            record.lastSeason)
                        )
                      }
                    >
                      比較表 &nbsp;{' '}
                      <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                    </Button>
                  </Space>
                ) : (record.survey_code === '30010' ||
                    record.survey_code === '90101') &&
                  record.e3_option ? ( //E3-Survey単体orコース　 + パーソナル診断Optionあり
                  <Space>
                    <Button
                      type={'primary'}
                      size="small"
                      onClick={(e) => showConfirm(record, 30010)}
                      disabled={record.survey_status === 5 ? false : true}
                    >
                      サーベイ結果確認 &nbsp;{' '}
                      <BsBoxArrowUpRight style={{ fontSize: '16px' }} />
                    </Button>
                  </Space>
                ) : (
                  <Button type={'primary'} size="small" disabled={true}>
                    個別結果はありません
                  </Button>
                )}
              </span>
            )}
          />
        </Table>
      </div>
      <Modal
        title="サーベイ結果ファイル生成中..."
        open={isModalVisible}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>
    </Fragment>
  );
}

export default MySurveyPage;
