import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;
const { Text } = Typography;

function GuidePage(props) {
  const { tokenInfo } = props;
  if (!tokenInfo) return null;
  // console.log({ tokenInfo });
  switch (tokenInfo.survey_code) {
    case '10010':
      return (
        <div>
          <Title>人事評価力診断</Title>
          <Title level={4}>【　目　的　】</Title>
          <Text>
            こちらは、評価を行う際の評価傾向について自己診断するものです。
          </Text>
          <br />
          また、それに加えて、評価者に必要とされる一般的な知識と意識を診断するものです。
          <br />
          <br />
          <Title level={4}>【診断受講のための注意事項】</Title>
          <br />
          <ul>
            <li>設問は全部で80問です。</li>
            <li>
              設問には２択（「その通りである」「その通りではない」）と３択（「その通りである」「その通りではない」「どちらともいえない」）があります。
            </li>
            <li>
              評価の段階は５段階（５・４・３・２・１）で、最高評価は５で最低評価は「１」です。
            </li>
            <li>
              設問にある「」の中身は評価項目の名称です。特に定義の意味が分からなくても回答できるようになっていますが、表示された漢字そのままの意味で考えていただくことで回答しやすくなります。
            </li>
          </ul>
          <Text type="warning">
            ※成績評価を目標管理で行っていない場合には、目標を仕事の出来栄えと読み替えてお答えください。
          </Text>
          <br />
        </div>
      );
    case '50010':
      return (
        <div>
          <Title>モチベーション・トレンド診断</Title>
          <Title level={4}>【　目　的　】</Title>
          <Text>
            こちらの診断テストは、皆さんの仕事に対するモチベーションや取り組み姿勢、考え方などを知るためのアンケートです。
          </Text>
          <br />
          全部で６０問です。もれのないようにすべて回答ください。
          <br />
          <br />
          <Title level={4}>【診断受講のための注意事項】</Title>
          <br />
          <ul>
            <li>設問は全部で60問です。</li>
            <li>設問には７択です。</li>
            <li>全ての設問にお答えください。</li>
          </ul>
          <br />
        </div>
      );
    case '60010':
      return (
        <div>
          <Title>フォロワーシップ自己診断</Title>

          <Title level={4}>【　目　的　】</Title>
          <Text>
            本アンケートは、"フォロワーシップ"について、
            <br />
            <br />
            現在皆さんがどの程度、職場で意識して考え行動しているかを、
            <br />
            <br />
            セルフチェックして内省の機会としていただくことを目的としています。
            <br />
            <br />
            （診断結果は、回答提出後すぐに確認することができます）
            <br />
            <br />
          </Text>
          <br />
          <Title level={4}>【アンケート内容】</Title>
          <br />
          <Text>
            ・設問数：全40問（選択式）
            <br />
            <br />
            ・回答時間：約10分
          </Text>
          <br />
        </div>
      );
    case '20110':
      // console.log({ tokenInfo });
      //tokenInfo.surveyNameはseason名＋questionSet名
      if (
        tokenInfo.surveyName.includes('事前') ||
        tokenInfo.surveyName.includes('研修受講前')
      ) {
        return (
          <div>
            <Title level={3}>
              {tokenInfo.user.company} {tokenInfo.tName}　研修受講前アンケート
            </Title>
            <Text>{tokenInfo.tName}の事前アンケートのご案内です。</Text>
            <Title level={4}>【　目　的　】</Title>
            <Text>
              このアンケートの目的は、みなさんが置かれている状況を伺って、当日の研修運営に反映させたり、
              <br />
              研修後の職場実践をご支援したりすることです。
            </Text>
            <br />
            <br />
            <Title level={4}>【結果について】</Title>
            <Text>
              結果は今後の実践支援策の検討にのみ、使用いたします。
              <br />
              回答内容が他の目的で使用されることはありません。
              <br />
              率直なご回答・ご意見をお願いいたします。
            </Text>
            <br />
            <br />
            <Title level={4}>【アンケートの所要時間】</Title>
            <Text>所要時間は、約5分です。</Text>

            <br />
          </div>
        );
      } else if (
        tokenInfo.surveyName.includes('直後') ||
        tokenInfo.surveyName.includes('研修受講後')
      ) {
        return (
          <div>
            <Title level={3}>
              {tokenInfo.user.company} {tokenInfo.tName}　研修受講後アンケート
            </Title>
            <Text>{tokenInfo.tName}に関するアンケートのご案内です。</Text>
            <Title level={4}>【　目　的　】</Title>
            <Text>
              このアンケートは、研修後の職場実践をご支援するにあたり、職場の環境・みなさんの状況を伺う目的で、実施させていただくものです。
            </Text>
            <br />
            <br />
            <Title level={4}>【結果について】</Title>
            <Text>
              結果は今後の実践支援策の検討にのみ、使用いたします。
              <br />
              回答内容が他の目的で使用されることはありません。
              <br />
              率直なご回答・ご意見をお願いいたします。
            </Text>
            <br />
            <br />
            <Title level={4}>【アンケートの所要時間】</Title>
            <Text>所要時間は、約5分です。</Text>

            <br />
          </div>
        );
      } else if (
        tokenInfo.surveyName.includes('事後') ||
        tokenInfo.surveyName.includes('研修事後')
      ) {
        return (
          <div>
            <Title level={3}>
              {tokenInfo.user.company} {tokenInfo.tName}　研修事後アンケート
            </Title>
            <Text>{tokenInfo.tName}に関するアンケートのご案内です。</Text>
            <Title level={4}>【　目　的　】</Title>
            <Text>
              このアンケートは、研修後の職場実践をご支援するにあたり、職場の環境・みなさんの状況を伺う目的で、実施させていただくものです。
              <br />
              また、これを機に、ぜひ受講内容を再度振り返ってみてください。新たに活用できることが見つかるかもしれません。
            </Text>
            <br />
            <br />
            <Title level={4}>【結果について】</Title>
            <Text>
              結果は今後の実践支援策の検討にのみ、使用いたします。
              <br />
              回答内容が他の目的で使用されることはありません。
              <br />
              率直なご回答・ご意見をお願いいたします。
            </Text>
            <br />
            <br />
            <Title level={4}>【アンケートの所要時間】</Title>
            <Text>所要時間は、約5分です。</Text>

            <br />
          </div>
        );
      } else if (tokenInfo.surveyName.includes('日程調査')) {
        return (
          <div>
            <Title level={3}>
              {tokenInfo.user.company} {tokenInfo.tName}日程調整アンケート
            </Title>
            <Title level={4}>【　目　的　】</Title>
            <Text>
              こちらは{tokenInfo.tName}の参加日程をお伺いするアンケートです。
              <br />
              今回研修へ不参加をご希望の方も必ずご回答をお願いいたします。
            </Text>
            <br />
            <br />
            <Title level={4}>【参加日程の確定について】</Title>
            <Text>
              皆さまの参加日程は後日メールにてご連絡いたします。
              <br />
              なるべくご希望にそうよう調整いたしますが、１回の開催人数に制限があるため
              <br />
              ご希望通りとならない場合もございますことをあらかじめご了承願います。
            </Text>
            <br />
            <br />
            <Title level={4}>【アンケートの所要時間】</Title>
            <Text>所要時間は、約5分です。</Text>

            <br />
          </div>
        );
      } else {
        return (
          <div>
            <Title level={3}>{tokenInfo.tName}アンケート</Title>
            <Text>{tokenInfo.tName}アンケートのご案内です。</Text>
            <Title level={4}>【　目　的　】</Title>
            <Text>
              この度は本アンケートにご協力していただきありがとうございます。
              <br />
              このアンケートは、{tokenInfo.tName}のためのアンケートです。
            </Text>
            <br />
            <br />
            <Title level={4}>【結果について】</Title>
            <Text>
              結果は今後の支援策の検討にのみ、使用いたします。
              <br />
              回答内容が他の目的で使用されることはありません。
              <br />
              率直なご回答・ご意見をお願いいたします。
            </Text>
            <br />
            <br />
            <Title level={4}>【アンケートの所要時間】</Title>
            <Text>所要時間は、約5分です。</Text>

            <br />
          </div>
        );
      }
    case '30010':
      return (
        <div>
          <Title level={3}>従業員エンゲージメント調査『E³-survey』</Title>
          <Text>
            この度は、アンケートにご協力いただきありがとうございます。
            <br />
            あなたの回答が組織改善の大事な一歩になります！
            <br />
          </Text>
          <Title level={4}>アンケート調査内容</Title>
          <Text>
            設問数：全124問（選択式120問 / 自由回答式4問）
            <br />
            回答時間：約20分想定
          </Text>
          <Title level={4}>回答上の注意点</Title>
          <Text>
            質問文をよく読み、率直に回答をお願いします。
            <br />
            自由回答以外の選択式設問には、必ず全ての設問に回答してください。
            <br />
            すべての質問に同じ回答を選んだり、意図的に偏った回答はお控えください。
          </Text>
          <Title level={4}>注意事項</Title>
          <Text>
            <ul>
              <li>
                皆様からの回答内容は、秘密を厳守しながら細心の注意を払って取り扱います。
              </li>
              <li>
                本調査は、会社全体や部署全体などの現状や課題の把握を目的としております。
              </li>
              <li>
                本調査への回答内容は「リ・カレント株式会社」が集計／分析します。
              </li>
              <li>
                皆様の回答により、回答者個人に悪影響や不利益が及ぶことは一切ありません。
              </li>
            </ul>
          </Text>
        </div>
      );
    case '40010':
      if (tokenInfo.questionSet.type_360 === 'cup') {
        return (
          <div>
            <Title level={3}>{tokenInfo.tName}</Title>
            <Text>
              この度は、アンケートにご協力いただきありがとうございます。
              <br />
              あなたの回答が組織改善の大事な一歩になります！
              <br />
            </Text>
            <Title level={4}>アンケート調査内容</Title>
            <Text>
              設問数：全36~41問（選択式36~38問 / 自由回答式0~3問）
              <br />
              回答時間：約15~20分想定
            </Text>
            <Title level={4}>回答上の注意点</Title>
            <Text>
              質問文をよく読み、率直に回答をお願いします。
              <br />
              自由回答以外の選択式設問には、必ず全ての設問に回答してください。
              <br />
              すべての質問に同じ回答を選んだり、意図的に偏った回答はお控えください。
            </Text>
            <Title level={4}>注意事項</Title>
            <Text>
              <ul>
                <li>
                  皆様からの回答内容は、秘密を厳守しながら細心の注意を払って取り扱います。
                </li>
                <li>
                  本調査への回答内容は「リ・カレント株式会社」が集計／分析します。
                </li>
                <li>
                  ブラウザを閉じたり、別のメニューに遷移してしまいますと、入力した回答は破棄されます。
                </li>
                <li>
                  誤作動の恐れがありますので、複数のウィンドウやタブで、回答画面を同時に開かないでください。
                </li>
                <li>
                  予期しないデー夕送信を防ぐため、ブラウザの「戻る」「更新」は使用しないでください。
                </li>
              </ul>
            </Text>
          </div>
        );
      } else if (tokenInfo.questionSet.type_360 === '6x6') {
        return (
          <div>
            <Title level={3}>{tokenInfo.tName}</Title>
            <Text>
              この度は、アンケートにご協力いただきありがとうございます。
              <br />
              あなたの回答が組織改善の大事な一歩になります！
              <br />
            </Text>
            <Title level={4}>アンケート調査内容</Title>
            <Text>
              設問数：全39問（選択式36問 / 記述式3問）
              <br />
              回答時間：約15~20分想定
            </Text>
            <Title level={4}>回答上の注意点</Title>
            <Text>
              質問文をよく読み、率直に回答をお願いします。
              <br />
              選択式設問に加えて、記述式設問も大切な気づきになりますので、全ての設問に回答してください。
              <br />
              すべての質問に同じ回答を選んだり、意図的に偏った回答はお控えください。
            </Text>
            <Title level={4}>注意事項</Title>
            <Text>
              <ul>
                <li>
                  皆様からの回答内容は、秘密を厳守しながら細心の注意を払って取り扱います。
                </li>
                <li>
                  本調査への回答内容は「リ・カレント株式会社」が集計／分析します。
                </li>
                <li>
                  ブラウザを閉じたり、別のメニューに遷移してしまいますと、入力した回答は破棄されます。
                </li>
                <li>
                  誤作動の恐れがありますので、複数のウィンドウやタブで、回答画面を同時に開かないでください。
                </li>
                <li>
                  予期しないデー夕送信を防ぐため、ブラウザの「戻る」「更新」は使用しないでください。
                </li>
              </ul>
            </Text>
          </div>
        );
      } else if (
        tokenInfo.questionSet.type_360 === '6x5' ||
        tokenInfo.questionSet.type_360 === '5x6'
      ) {
        return (
          <div>
            <Title level={3}>{tokenInfo.tName}</Title>
            <Text>
              この度は、アンケートにご協力いただきありがとうございます。
              <br />
              あなたの回答が組織改善の大事な一歩になります！
              <br />
            </Text>
            <Title level={4}>アンケート調査内容</Title>
            <Text>
              設問数：全33問（選択式30問 / 記述式3問）
              <br />
              回答時間：約15~20分想定
            </Text>
            <Title level={4}>回答上の注意点</Title>
            <Text>
              質問文をよく読み、率直に回答をお願いします。
              <br />
              選択式設問に加えて、記述式設問も大切な気づきになりますので、全ての設問に回答してください。
              <br />
              すべての質問に同じ回答を選んだり、意図的に偏った回答はお控えください。
            </Text>
            <Title level={4}>注意事項</Title>
            <Text>
              <ul>
                <li>
                  皆様からの回答内容は、秘密を厳守しながら細心の注意を払って取り扱います。
                </li>
                <li>
                  本調査への回答内容は「リ・カレント株式会社」が集計／分析します。
                </li>
                <li>
                  ブラウザを閉じたり、別のメニューに遷移してしまいますと、入力した回答は破棄されます。
                </li>
                <li>
                  誤作動の恐れがありますので、複数のウィンドウやタブで、回答画面を同時に開かないでください。
                </li>
                <li>
                  予期しないデー夕送信を防ぐため、ブラウザの「戻る」「更新」は使用しないでください。
                </li>
              </ul>
            </Text>
          </div>
        );
      }
    case '70010':
      return (
        <div>
          <Title level={3}>女性活躍組織診断</Title>
          <Text>
            画面の案内に従い、アンケートに回答してください
            <br />
            所要時間は約20分です（全100問）
            <br />
          </Text>
          <Title level={4}>【回答上の注意】</Title>
          <Text>
            自由回答式・選択式設問、必ず全ての設問に回答してください
            <br />
            設問内容についてあまり深く考え過ぎず、直感的に感じたことをご回答ください
            <br />
            アンケート内の<b>「仕事」</b>とは、職業や所属企業ではなく、
            <b>携わっている職務や業務</b>を想定して回答ください
          </Text>
        </div>
      );
    default:
      break;
  }
}

export default GuidePage;
